<template>
  <div class="filter-project">
    <b-form-group
      label="Afficher les projets correspondant à TOUTES ces conditions :"
      v-if="
        (!disabled && localValue.and) ||
          (disabled && localValue.and && localValue.and.length)
      "
    >
      <div v-if="localValue.and">
        <div
          class="list-element mb-2"
          v-for="(item, index) in localValue.and"
          :key="index"
        >
          <Multiselect
            class="value-type ml-2"
            v-model="item.object"
            :options="selectObject"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="text"
            track-by="value"
            :preselect-first="true"
            :allow-empty="false"
            :disabled="disabled"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>
          <Multiselect
            class="value-type ml-2"
            v-model="item.field_key"
            :options="getFieldsTHToFiltre"
            @input="changeKeyField(item.field_key, index, 'and')"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="name"
            track-by="key"
            :allow-empty="false"
            :disabled="disabled"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>
          <Multiselect
            class="value-type ml-2"
            v-model="item.operator"
            :options="selectedOperatorsAnd[index] || []"
            @input="changeOperator(item.operator)"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="label"
            track-by="key"
            :allow-empty="false"
            :disabled="disabled"
          >
            <span slot="noResult">Aucun opérateur arithmétique trouvé.</span>
            <span slot="noOptions">Aucun opérateur arithmétique trouvé.</span>
          </Multiselect>
          <date-picker
            class="value-type ml-2"
            v-if="item.type && item.type == 'date'"
            :disabled="item.isHidden || disabled"
            format="YYYY-MM-DD"
            v-model="item.value"
            value-type="format"
            type="date"
          ></date-picker>
          <b-form-input
            class="value-type ml-2"
            v-if="item.type && item.type == 'text'"
            v-model="item.value"
            :disabled="item.isHidden || disabled"
          ></b-form-input>
          <Multiselect
            v-if="!item.type"
            :disabled="item.isHidden || disabled"
            class="value-type ml-2"
            v-model="item.value"
            @select="changeValue"
            :options="selectedValueAnd[index] || []"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="name"
            track-by="id"
            :allow-empty="false"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>
          <div class="bolc-and">
            <div class="value-type ml-2">ET</div>
            <div
              class="action ml-2"
              @click="removecondition(index, 'and')"
              v-if="!disabled"
            >
              <font-awesome-icon class icon="trash-alt" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="add-to-list-action"
        @click="addCondition('and')"
        v-if="!disabled"
      >
        <font-awesome-icon class="" icon="plus" /> Ajouter une condition
      </div>
    </b-form-group>
    <b-form-group
      label="Et correspond à une de ces conditions :"
      v-if="
        (!disabled && localValue.or) ||
          (disabled && localValue.or && localValue.or.length)
      "
    >
      <div v-if="localValue.or">
        <div
          class="list-element mb-2"
          v-for="(item, index) in localValue.or"
          :key="index"
        >
          <Multiselect
            class="value-type ml-2"
            v-model="item.object"
            :options="selectObject"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="text"
            track-by="value"
            :preselect-first="true"
            :allow-empty="false"
            :disabled="disabled"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>
          <Multiselect
            class="value-type ml-2"
            v-model="item.field_key"
            :options="getFieldsTHToFiltre"
            @input="changeKeyField(item.field_key, index, 'or')"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="name"
            track-by="key"
            :allow-empty="false"
            :disabled="disabled"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>
          <Multiselect
            class="value-type ml-2"
            v-model="item.operator"
            :options="selectedOperatorsOr[index] || []"
            @input="changeOperator(item.operator)"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="label"
            track-by="key"
            :allow-empty="false"
            :disabled="disabled"
          >
            <span slot="noResult">Aucun opérateur arithmétique trouvé.</span>
            <span slot="noOptions">Aucun opérateur arithmétique trouvé.</span>
          </Multiselect>
          <date-picker
            class="value-type ml-2"
            v-if="item.type && item.type == 'date'"
            :disabled="item.isHidden || disabled"
            format="YYYY-MM-DD"
            v-model="item.value"
            value-type="format"
            type="date"
          ></date-picker>
          <b-form-input
            class="value-type ml-2"
            v-if="item.type && item.type == 'text'"
            v-model="item.value"
            :disabled="item.isHidden || disabled"
          ></b-form-input>
          <Multiselect
            v-if="!item.type"
            :disabled="item.isHidden || disabled"
            class="value-type ml-2"
            v-model="item.value"
            @select="changeValue"
            :options="selectedValueOr[index] || []"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="name"
            track-by="id"
            :allow-empty="false"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>
          <div class="bloc-or">
            <div class="value-type ml-2">OU</div>
            <div
              class="action ml-2"
              @click="removecondition(index, 'or')"
              v-if="!disabled"
            >
              <font-awesome-icon class icon="trash-alt" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="add-to-list-action"
        @click="addCondition('or')"
        v-if="!disabled"
      >
        <font-awesome-icon class="" icon="plus" /> Ajouter une condition
      </div>
    </b-form-group>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
  components: {
    Multiselect,
    DatePicker
  },
  props: {
    fields: { required: true },
    disabled: { required: false },
    update: { required: false },
    typeProject: { required: false },
    value: {
      default: () => {
        return {
          conditions: {
            and: [],
            or: []
          }
        }
      }
    }
  },
  data() {
    return {
      localValue: { ...this.value },
      selectedOperatorsAnd: [],
      selectedValueAnd: [],
      selectedOperatorsOr: [],
      selectedValueOr: [],
      selectObject: [
        {
          value: 'project',
          text:
            this.typeProject && this.typeProject.name
              ? this.typeProject.name
              : 'Project'
        }
      ],
      changeFieldkey: {
        index: null,
        condition: null
      }
    }
  },

  methods: {
    ...mapActions(['fetchOperatorsToFieldsTH', 'fetchvalueOfFields']),
    async changeKeyField(keyField, index, condition) {
      await this.fetchOperatorsToFieldsTH({
        field_id: keyField.id,
        field_key: keyField.key
      })
      this.changeFieldkey = {
        index: index,
        condition: condition
      }
      if (this.localValue && this.localValue.and.length) {
        this.selectedOperatorsAnd = []
        this.selectedValueAnd = []
        this.appelValue()
      }
      if (this.localValue && this.localValue.or.length) {
        this.selectedOperatorsOr = []
        this.selectedValueOr = []
        this.appelValue()
      }
    },
    changeOperator() {
      this.localValue.and.map(and => {
        and.isHidden = false
        if (
          and.operator.key == 'IS NULL' ||
          and.operator.key == 'IS NOT NULL'
        ) {
          and.isHidden = true
          and.value = null
        }
      })
      this.localValue.or.map(or => {
        or.isHidden = false
        if (or.operator.key == 'IS NULL' || or.operator.key == 'IS NOT NULL') {
          or.isHidden = true
          or.value = null
        }
      })
    },
    addCondition(etatFiltre) {
      if (etatFiltre == 'and') {
        this.localValue.and.push({
          object: {
            value: 'project',
            text:
              this.typeProject && this.typeProject.name
                ? this.typeProject.name
                : 'Project'
          },
          field_key: '',
          operator: '',
          value: '',
          isHidden: false,
          type: ''
        })
        for (let index = 0; index < this.localValue.and.length; index++) {
          const keyField = this.localValue.and[index]
          if (!keyField.field_key) {
            this.selectedOperatorsAnd[index] = []
            this.selectedValueAnd[index] = []
          }
        }
      }
      if (etatFiltre == 'or') {
        this.localValue.or.push({
          object: {
            value: 'project',
            text:
              this.typeProject && this.typeProject.name
                ? this.typeProject.name
                : 'Project'
          },
          field_key: '',
          operator: '',
          value: '',
          isHidden: false,
          type: ''
        })
        for (let index = 0; index < this.localValue.or.length; index++) {
          const keyField = this.localValue.or[index]
          if (!keyField.field_key) {
            this.selectedOperatorsOr[index] = []
            this.selectedValueOr[index] = []
          }
        }
      }
    },
    removecondition(index, etatFiltre) {
      if (etatFiltre == 'and') {
        this.localValue.and.splice(index, 1)
      } else {
        this.localValue.or.splice(index, 1)
      }
      this.changeValue()
    },
    changeValue() {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
    },
    async appelValue() {
      if (this.localValue && this.localValue.and) {
        for (let index = 0; index < this.localValue.and.length; index++) {
          const keyField = this.localValue.and[index]
          if (
            this.changeFieldkey.index == index &&
            this.changeFieldkey.condition === 'and' &&
            (keyField.value || keyField.operator)
          ) {
            keyField.operator = ''
            keyField.value = ''
          }
          keyField.isHidden = false
          keyField.type = ''
          if (
            keyField.operator.key == 'IS NULL' ||
            keyField.operator.key == 'IS NOT NULL'
          ) {
            keyField.isHidden = true
            keyField.value = null
          }
          if (keyField.field_key) {
            //
            if (!keyField.object) {
              keyField.object = {
                value: 'project',
                text:
                  this.typeProject && this.typeProject.name
                    ? this.typeProject.name
                    : 'Project'
              }
            }
            // Type de champ
            if (
              this.getOperatorTHToFiltre[keyField.field_key.key] &&
              this.getOperatorTHToFiltre[keyField.field_key.key].type
            ) {
              keyField.type = this.getOperatorTHToFiltre[
                keyField.field_key.key
              ].type
            }
            // valeurs de champ operators
            this.selectedOperatorsAnd[index] = this.getOperatorTHToFiltre[
              keyField.field_key.key
            ].operators
            // valeur possible de champ de fields
            if (
              this.getOperatorTHToFiltre[keyField.field_key.key]
                .possible_values &&
              this.getOperatorTHToFiltre[keyField.field_key.key].possible_values
                .data
            ) {
              this.selectedValueAnd[index] = this.getOperatorTHToFiltre[
                keyField.field_key.key
              ].possible_values.data
            }
          }
        }
      }
      if (this.localValue && this.localValue.or) {
        for (let index = 0; index < this.localValue.or.length; index++) {
          const keyField = this.localValue.or[index]
          if (
            this.changeFieldkey.index == index &&
            this.changeFieldkey.condition === 'or' &&
            (keyField.value || keyField.operator)
          ) {
            keyField.operator = ''
            keyField.value = ''
          }
          keyField.isHidden = false
          keyField.type = ''
          if (
            keyField.operator.key == 'IS NULL' ||
            keyField.operator.key == 'IS NOT NULL'
          ) {
            keyField.isHidden = true
            keyField.value = null
          }
          if (keyField.field_key) {
            //
            if (!keyField.object) {
              keyField.object = {
                value: 'project',
                text:
                  this.typeProject && this.typeProject.name
                    ? this.typeProject.name
                    : 'Project'
              }
            }
            // Type de champ
            if (
              this.getOperatorTHToFiltre[keyField.field_key.key] &&
              this.getOperatorTHToFiltre[keyField.field_key.key].type
            ) {
              keyField.type = this.getOperatorTHToFiltre[
                keyField.field_key.key
              ].type
            }
            // valeurs de champ operators
            this.selectedOperatorsOr[index] = this.getOperatorTHToFiltre[
              keyField.field_key.key
            ].operators
            // valeur possible de champ de fields
            if (
              this.getOperatorTHToFiltre[keyField.field_key.key]
                .possible_values &&
              this.getOperatorTHToFiltre[keyField.field_key.key].possible_values
                .data
            ) {
              this.selectedValueOr[index] = this.getOperatorTHToFiltre[
                keyField.field_key.key
              ].possible_values.data
            }
          }
        }
      }
    },
    async affichageAppelValue() {
      if (this.localValue && this.localValue.and) {
        for (let index = 0; index < this.localValue.and.length; index++) {
          const keyField = this.localValue.and[index]
          if (
            this.changeFieldkey.index == index &&
            this.changeFieldkey.condition === 'and' &&
            (keyField.value || keyField.operator)
          ) {
            keyField.operator = ''
            keyField.value = ''
          }
          keyField.isHidden = false
          keyField.type = ''
          if (
            keyField.operator.key == 'IS NULL' ||
            keyField.operator.key == 'IS NOT NULL'
          ) {
            keyField.isHidden = true
            keyField.value = null
          }
          if (keyField.field_key) {
            //
            if (!keyField.object) {
              keyField.object = {
                value: 'project',
                text:
                  this.typeProject && this.typeProject.name
                    ? this.typeProject.name
                    : 'Project'
              }
            }
            // Type de champ
            if (this.getOperatorTHToFiltre[keyField.field_key.key].type) {
              keyField.type = this.getOperatorTHToFiltre[
                keyField.field_key.key
              ].type
            }
            // valeurs de champ operators
            this.selectedOperatorsAnd[index] = this.getOperatorTHToFiltre[
              keyField.field_key.key
            ].operators
            // valeur possible de champ de fields
            if (
              this.getOperatorTHToFiltre[keyField.field_key.key]
                .possible_values &&
              this.getOperatorTHToFiltre[keyField.field_key.key].possible_values
                .data
            ) {
              this.selectedValueAnd[index] = this.getOperatorTHToFiltre[
                keyField.field_key.key
              ].possible_values.data
              if (keyField.type != 'date' && keyField.type != 'text') {
                const value = this.selectedValueAnd[index].find(
                  e => e.id == keyField.value
                )
                keyField.value = value
              }
            }
          }
        }
      }
      if (this.localValue && this.localValue.or) {
        for (let index = 0; index < this.localValue.or.length; index++) {
          const keyField = this.localValue.or[index]
          if (
            this.changeFieldkey.index == index &&
            this.changeFieldkey.condition === 'or' &&
            (keyField.value || keyField.operator)
          ) {
            keyField.operator = ''
            keyField.value = ''
          }
          keyField.isHidden = false
          keyField.type = ''
          if (
            keyField.operator.key == 'IS NULL' ||
            keyField.operator.key == 'IS NOT NULL'
          ) {
            keyField.isHidden = true
            keyField.value = null
          }
          if (keyField.field_key) {
            //
            if (!keyField.object) {
              keyField.object = {
                value: 'project',
                text:
                  this.typeProject && this.typeProject.name
                    ? this.typeProject.name
                    : 'Project'
              }
            }
            // Type de champ
            if (this.getOperatorTHToFiltre[keyField.field_key.key].type) {
              keyField.type = this.getOperatorTHToFiltre[
                keyField.field_key.key
              ].type
            }
            // valeurs de champ operators
            this.selectedOperatorsOr[index] = this.getOperatorTHToFiltre[
              keyField.field_key.key
            ].operators
            // valeur possible de champ de fields
            if (
              this.getOperatorTHToFiltre[keyField.field_key.key]
                .possible_values &&
              this.getOperatorTHToFiltre[keyField.field_key.key].possible_values
                .data
            ) {
              this.selectedValueOr[index] = this.getOperatorTHToFiltre[
                keyField.field_key.key
              ].possible_values.data
              if (keyField.type != 'date' && keyField.type != 'text') {
                const value = this.selectedValueOr[index].find(
                  e => e.id == keyField.value
                )
                keyField.value = value
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getFieldsTHToFiltre', 'getOperatorTHToFiltre'])
  },
  watch: {
    value() {
      this.localValue = { ...this.value }
      if (this.disabled) {
        this.affichageAppelValue()
      }
    }
  },
  mounted() {
    if (this.disabled) {
      this.affichageAppelValue()
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-project {
  .add-to-list-action {
    color: #2dabe2;
    cursor: pointer;
    width: 22%;
    &.disabled {
      color: #ced4da;
      cursor: default;
    }
  }
  .list-element {
    display: flex;
    align-items: center;
    .value-type {
      flex-shrink: 3;
    }
    .bolc-and,
    .bloc-or {
      display: flex;
    }
    .action {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 990px) {
  .filter-project {
    .add-to-list-action {
      width: 40%;
    }
    .list-element {
      display: initial;
      .value-type {
        margin-bottom: 5px;
        max-width: initial;
      }
      .bolc-and,
      .bloc-or {
        display: flex;
        justify-content: flex-end;
      }
      .action {
        margin-left: 0px;
      }
    }
  }
}
</style>
<style lang="scss">
.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
  .mx-input {
    display: inline-block;
    width: 100%;
    height: 42px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    color: #35495e;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
  }
}
.filter-project {
  .list-element {
    .multiselect--disabled {
      background: #ededed;
      pointer-events: none;
      border-radius: 5px;
      opacity: 2.6;
    }
    .value-type {
      .multiselect__select {
        height: 40px;
      }
    }
  }
}
</style>
